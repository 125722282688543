.demo-container {
    position: relative;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    gap: 5rem;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255);
}

@media (max-width: 500px) {
    .demo-container {
        gap: 3.5rem;
    }
}

.demo-container .logo {
    padding-top: 2.5rem;
    justify-content: center;
    width: 100%;
    z-index: 10;
    display: flex;
}

.demo-container .bg {
    position: absolute;
    height: 30vh;
    width: 100%;
}

.demo-container .body {
    gap: 2.5rem;
    align-items: center;
    flex-direction: column;
    display: flex;
    margin-bottom: 8rem;
    z-index: 10;
    position: relative;
}

.demo-container .body .body-content {
    gap: .5rem;
    align-items: center;
    flex-direction: column;
    display: flex;
}

.demo-container .body .body-content .demo-title {
    line-height: 50px;
    font-weight: 500;
    font-size: 40px;
    color: black;
    text-align: center;
}

.demo-container .body .body-content .demo-subtitle {
    --tw-text-opacity: 1;
    color: rgb(121 121 121 / var(--tw-text-opacity));
    line-height: 32px;
    font-size: 25px;
    text-align: center;
}

@media (max-width: 500px) {
    .demo-container .body .body-content .demo-title {
        font-size: 32px;
        line-height: 40px;
    }
}

@media (max-width: 500px) {
    .demo-container .body .body-content .demo-subtitle {
        line-height: 26px;
        font-size: 20px;
    }
}

@media (max-width: 1024px) {
    .demo-container .body .body-content .demo-subtitle {
        font-size: 24px;
        width: 90%;
        line-height: 30px;
    }
}

.calendly-inline-widget {
    position: relative;
    min-width: 320px;
    width: 800px;
    height: 500px;
}

@media (max-width: 900px) {
    .calendly-inline-widget {
        width: 90vw;
    }
}

@media (min-width: 768px) {
    .calendly-inline-widget {
        height: 675px;
    }
}

@media (min-width: 1024px) {
    .calendly-inline-widget {
        height: 700px;
    }
}

.already-have-account {
    font-weight: 500;
    font-size: 16px;
    gap: .75rem;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 2.5rem;
}

.already-have-account > div {
    color: rgb(122, 122, 122) !important;
}

.already-have-account > a {
    color: rgb(0, 193, 239) !important;
}


@media not all and (min-width: 360px) {
    .already-have-account {
        gap: .25rem;
        flex-direction: column;
    }
}

@media not all and (min-width: 500px) {
    .already-have-account {
        font-size: 14px;
        gap: .25rem;
    }
}

.demo-footer {
    gap: 2rem;
    align-items: center;
    flex-direction: column;
    display: flex;
    margin-top: -2.5rem;
}

.demo-footer__title {
    font-size: 32px;
    text-align: center;
    color: black;
}

@media (max-width: 400px) {
    .demo-footer__title {
        line-height: 24px;
        font-size: 18px;
    }
}

@media (max-width: 500px) {
    .demo-footer__title {
        line-height: 26px;
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .demo-footer__title {
        width: 90%;
    }
}

@media (max-width: 1024px) {
    .demo-footer__title {
        line-height: 32px;
        font-size: 25px;
    }
}

.demo-images {
    gap: 1rem;
    display: none;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 1024px) {
    .demo-images-md {
        display: flex;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .demo-images-sm {
        display: flex;
    }
}

@media(max-width: 768px) {

    .demo-images-xs {
        display: flex;
        max-width: 350px !important;
    }
}

@media not all and (min-width: 500px) {
    .max-500\:w-\[100px\] {
        width: 100px;
    }
}
@media not all and (min-width: 500px) {
    .max-500\:h-\[85px\] {
        height: 85px;
    }
}

.demo-images__row {
    gap: .75rem;
    justify-content: center;
    align-items: center;
    display: flex;
}

.demo-images__item {
    padding: .5rem;
    --tw-border-opacity: 1;
    border-radius: 0.5rem;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 110px;
    display: flex;
    position: relative;
}

@media not all and (min-width: 1350px) {
    .demo-images__item {
        width: 150px;
        height: 105px;
    }
}

.border-\[\#CDCDCD\] {
    border-color: rgb(205 205 205);
}

.border-l-0 {
    border-left-width: 0;
}

.border {
    border-width: 1px;
}


.demo-images__img {
    object-fit: contain;
    max-width: 130px;
    max-height: 100px;
    user-select: none;
    -webkit-user-drag: none;
}

@media not all and (min-width: 1350px) {
    .demo-images__img {
        max-width: 120px;
    }
}

@media (max-width: 500px) {
    .demo-images__item {
        width: 100px;
        height: 85px;
    }
    .demo-images__img {
        max-width: 80px;
        max-height: 65px;
    }
}

.demo-bottom-img {
    background: white;
    --tw-rotate: 180deg;
    height: 30vh;
    bottom: 0;
    margin-bottom: -5rem;
    position: absolute;
    width: 100%;
    transform: rotate(180deg);
}
