@media screen and (max-width: 576px) {

    .research-page {
        .text-26 {
            font-size: 20px;
        }
    }

    .text-22 {
        font-size: 18px !important;
    }

    .text-35 {
        font-size: 22px !important;
    }

    .title-btn {
        width: 225px;
        padding: 0;
        overflow: hidden;
        position: relative;
        display: inline-block;
        text-align: center;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .home-video {
        img {
            transform: scale(1.2);
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            -o-transform: scale(1.2);
        }
    }

    .logo-beta {
        width: 130px !important;
    }

    .price-font {
        font-size: 13px;
    }

    .bg-products {
        box-shadow: 1px 1px 10px rgba(5, 5, 5, .45) !important;
        min-height: fit-content !important;
        max-height: fit-content !important;
    }

    .text-24 {
        font-size: 22px !important;
    }

    .payment-card {
        .w-fitcontent {
            width: 100% !important;
        }
    }

    .bg-gray2 {

        .button-gradient {
            background-color: #000;
            border: none !important;
            border-radius: 30px;
            color: #00F795 !important;
            position: static;
        }

        .form-control {
            background-color: #fff !important;
            padding-top: 10px;
            padding-bottom: 10px;
            border-radius: 50px !important;
            border-color: #e0dfdf !important;
            color: #000 !important;
            font-size: 20px;
            width: 100%;
        }
    }

    .margin-custom {
        margin-left: 0;
        margin-right: 0;
    }

    .progress {
        background-color: #ececec !important;
        height: 1.7rem !important;
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.3) !important;
        width: 100%;
    }

    .progress-value {
        position: absolute !important;
        top: 0px !important;
        left: 45% !important;
        font-size: 14px;
        font-weight: bold;
        color: #000;
    }

    .progress-bar {
        background-image: linear-gradient(to right, #00C4E9, #00F795);
        border-radius: 15px !important;
        padding-left: 20px;
        padding-right: 20px;
        color: #000;
        font-family: $ff-inter-semi;
        margin: 2px;
        width: 100%;
    }

    .step-success {
        background: URL('../img//Priya_AI/bg-step.png') !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        background-position: 100% !important;
        box-shadow: 0 10px 15px rgba(5, 5, 5, .45) !important;
        width: fit-content !important;
        margin: auto;
        border-radius: 10px;
    }


    .email-text {
        font-size: 12px !important;
    }

    .indexedStep {
        width: 25px !important;
        height: 25px !important;
        font-size: 12px !important;
    }

    .logo-mobile {
        width: 70px !important;
    }

    .banner-priya {
        width: 130px;
    }

    .font-banner,
    .font-banner-small {
        font-size: 36px !important;
        font-weight: 900 !important;
        letter-spacing: 5px;
    }

    .font-banner-research {
        font-size: 30px !important;
        font-weight: 900 !important;
        letter-spacing: 5px;
    }

    .login-modal {
        .btn-white {
            font-size: 16px !important;
        }

        .text-18 {
            font-size: 16px !important;
        }
    }

    .integration-modal {
        p {
            font-size: 18px !important;
        }

        button {
            font-size: 16px !important;
        }
    }

    .btn-white {
        font-size: 16px !important;
    }

    .work-head {
        font-size: 24px !important;
        line-height: 30px !important;
    }


    h1 {
        font-size: 25px !important;
    }

    .bg-card {
        background-color: #fff;
        box-shadow: 1px 1px 15px rgba(5, 5, 5, .45) !important;
        min-height: fit-content !important;
        max-height: fit-content !important;

        h3 {
            font-size: 18px !important;
        }
    }

    .graph-items {
        p {
            font-size: 12px !important;
        }
    }

    .bg-round {
        border: 1px solid #7C7C7C;
        border-radius: 50px;
        width: 270px !important;
    }

    .btn-width {
        width: 100%;
    }

    ::placeholder {
        font-size: 14px !important;
        font-family: $ff-inter-medium;
        color: #9e9c9c !important;
    }

    .react-select__placeholder {
        font-size: 14px !important;
        font-family: $ff-inter-medium;
        color: #9e9c9c !important;
    }

}

@media screen and (max-width: 767px) {
    .width-75 {
        width: 100% !important;
    }

    .width-100 {
        width: 100% !important;
    }

    .mobileview-btn {
        .text-14 {
            font-size: 12px !important;
        }
    }

    .bg-products {
        .text-17 {
            font-size: 14px !important;
        }
    }


    .payment-screen .payment-card {
        height: 100vh !important;
    }

    .height-bill-settings {
        .payment-card {
            height: 100% !important;
        }
    }

    .settings-screen {
        width: 100% !important;
    }

    #integration-modal {
        .modal-content {
            background-color: #fff !important;
            background: URL('../img//Priya_AI/bg-step.png') !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            background-position: 100% !important;
            box-shadow: 0 10px 15px rgba(5, 5, 5, .45) !important;
            border: 3px solid #00C4E9 !important;
            border-radius: 6px !important;
            min-height: fit-content;
            max-height: fit-content;
            position: static !important;

            .modal-body {
                padding: 10px !important;
            }
        }
    }

    #integration-modal-step4 {
        .modal-content {
            background-color: #fff !important;
            background: URL('../img//Priya_AI/bg-step.png') !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            background-position: 100% !important;
            box-shadow: 0 10px 15px rgba(5, 5, 5, .45) !important;
            border: 3px solid #00C4E9 !important;
            border-radius: 6px !important;
            min-height: fit-content;
            max-height: fit-content;
            position: static !important;

            .modal-body {
                padding: 10px !important;
                height: 100vh !important;
            }
        }
    }

    .banner-text {
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: white;
        font-size: 3em !important;
        font-weight: 700 !important;
        color: #f5f5f5;
        width: -moz-available;
        background: linear-gradient(93.11deg, #FF0000 1.45%, #FF0000 1.47%, #801515 234.45%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-family: $ff-bold !important;
    }

    h1 {
        font-size: 35px !important;
    }

    .h-100vh {
        height: 100% !important;
    }

}

@media screen and (max-width: 1199px) {
    .text-w-75 {
        width: 100% !important;
    }

    .rdt_TableRow {
        border-bottom: none !important;
        box-shadow: 1px 1px 5px #8a8b8b !important;
        margin: 8px 0px !important;
        display: flex;
        justify-content: space-between !important;
        padding: 10px 0px;
        width: 100% !important;
    }

    .top-card {
        position: static;
        width: auto;
    }

    .rdt_TableBody {
        border: none !important;
        box-shadow: none !important;
        width: 100vw !important;
    }

    .card-head {
        margin-top: 80px;
    }

    section {
        padding-left: 20px !important;
        padding-right: 20px !important;
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    .rdt_TableCell {
        width: max-content !important;
        padding-right: 0px !important;
    }

    .project-section {
        .navbar-collapse {
            width: 100vw !important;
            position: absolute !important;
            z-index: 1000 !important;
            left: 0px !important;
            right: 0px !important;
            margin: auto !important;
            background-color: #171717 !important;
            top: 0;
            border-radius: none !important;
        }
    }

    .nav-tabs.nav-fill {
        width: 100% !important;
        display: flex;
        flex-flow: row;
    }
}

@media screen and (max-width: 1399px) {
    .current-price-box {
        img {
            width: 28px;
        }
    }

    .product-info {
        .text-17 {
            font-size: 15px !important;
        }
    }

    .current-price {
        .text-16 {
            font-size: 15px !important;
        }

        h4 {
            font-size: 15px !important;
        }
    }

}

@media screen and (max-width: 991px) {
    .bg-products {
        box-shadow: 1px 1px 10px rgba(5, 5, 5, .45) !important;
        min-height: 280px !important;
        max-height: 280px !important;
    }

    .nav-tabs.nav-fill {
        width: 100% !important;
        display: flex;
        flex-flow: column !important;
    }

    .active-tab {
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
    }

    .bg-works {
        background-size: inherit !important;
    }

    .step-success {
        background: URL('../img//Priya_AI/bg-step.png') !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        background-position: 100% !important;
        box-shadow: 0 10px 15px rgba(5, 5, 5, .45) !important;
        width: fit-content !important;
        height: fit-content !important;
        margin: auto;
        border-radius: 10px;
    }

    .position-success-button {
        position: static;
    }

    .position-success-content {
        position: static;
    }

    .position-abs {
        position: static !important;
    }

    .navbar-collapse {
        background: #171717 !important;
        position: absolute;
        width: calc(100% - 1.4rem);
        height: auto !important;
        left: 0;
        top: -50px;
        margin: 0.7rem;
        margin-left: 0.7rem;
        background: #1f2251;
        border-radius: 0.2857rem;
        padding: 1.4rem;
    }

    .bg-community-box {
        background: linear-gradient(to right, rgb(71, 43, 43), rgb(82, 27, 27));
        border-radius: 19.3145px;
        border: 1px solid #FF0000;
        min-height: 200px;
        max-height: 200px;
    }

    .nav-section {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

}

@media (min-width: 1200px) {
    .products-row .col-xl-3 {
        flex: 0 0 33.33333% !important;
        max-width: 33.33333% !important;
    }
}

@media (min-width: 1600px) {
    .products-row .col-xl-3 {
        flex: 0 0 25% !important;
        max-width: 25% !important;
    }
}

@media (min-width: 1800px) {
    .image-style {
        border-radius: 20px;
        width: 500px;
        height: 250px !important;
    }
}